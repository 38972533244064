<template>
  <div>
    <v-toolbar
      dense
      height="36"
      class="test-area__toolbar mb-4">
      <v-btn-toggle
        v-model="buttonType"
        :mandatory="false"
        :column="false">
        <v-btn
          text
          small
          value="text">
          Text only
        </v-btn>
        <v-btn
          text
          small
          value="textAndIcon">
          Text and Icon
        </v-btn>
        <v-btn
          text
          small
          value="icon">
          Icon only
        </v-btn>
      </v-btn-toggle>
      <v-divider
        class="mr-2"
        vertical/>
      <v-btn
        text
        small
        @click="$toggle.isLoading">
        {{ isLoading ? 'stop' : 'start' }} loaders
      </v-btn>
      <v-divider
        class="mr-2"
        vertical/>
      <v-overflow-btn
        v-model="buttonIcon"
        :items="buttonIconOptions"
        :menu-props="{ maxHeight: 'fit-contents' }"
        height="36"
        flat
        editable
        label="Select icon"
        hide-details
        overflow/>
      <v-btn
        small
        color="secondary"
        href="https://vuetifyjs.com/en/api/v-btn/"
        target="_blank">
        <v-icon small>
          open_in_new
        </v-icon>
        Open Vuetify Btn Docs
      </v-btn>
      <v-btn
        small
        color="secondary"
        href="https://material.io/resources/icons/?style=baseline"
        target="_blank">
        <v-icon small>
          open_in_new
        </v-icon>
        Open Material Icons
      </v-btn>
    </v-toolbar>

    <div>
      <span class="test-area__header pr-3">Special buttons</span>
      <CyEditButton
        key="delete"
        @click="logThis"/>
      <CyDeleteButton
        key="edit"
        @click="logThis"/>
    </div>

    <div>
      <span class="test-area__header pr-3">Member links</span>
      <span class="px-3 flex-row">
        <CyFormsAssignOwner
          :form-content="missingOwner"
          class="d-inline"/>
        <CyButton
          theme="grey"
          variant="tertiary"
          light
          sm
          class="clickable"
          member-link>
          <CyMember
            :member="members[0]"
            simple/>
        </CyButton>
      </span>
      <span class="px-3">
        <CyButton
          v-for="member of members"
          :key="member.id"
          sm
          member-link="mini">
          <CyMember
            :member="member"
            avatar-only/>
        </CyButton>
      </span>
    </div>

    <v-row class="test-area mt-4">
      <template v-if="buttonType === 'text'">
        <v-col
          v-for="variant of ['primary', 'secondary', 'tertiary']"
          :key="`text-only:variant-${variant}`">
          <span class="test-area__header">{{ variant }}</span>
          <div>
            <CyButton
              v-for="(text, index) of ['some', 'grouped', 'buttons']"
              :key="`grouped:${index}__variant:${variant}`"
              :variant="variant"
              @click="logThis">
              {{ text }}
            </CyButton>
          </div>

          <table
            v-for="theme of ['primary', 'secondary', 'error', 'warning', 'success', 'accent', 'grey']"
            :key="`theme-${theme}`"
            aria-describedby="themes">
            <thead>
              <th>normal</th>
              <th>disabled</th>
              <th>loading</th>
            </thead>
            <tr
              v-for="size of ['xs', 'sm', undefined, 'lg', 'xl']"
              :key="size || 'normal'">
              <td>
                <CyButton
                  :key="`variant:${variant}__theme:${theme}__size:${size || 'normal'}`"
                  :theme="theme"
                  :variant="variant"
                  :xs="size === 'xs'"
                  :sm="size === 'sm'"
                  :lg="size === 'lg'"
                  :xl="size === 'xl'"
                  @click="logThis">
                  {{ size }} Button
                </CyButton>
              </td>
              <td>
                <CyButton
                  :theme="theme"
                  :variant="variant"
                  :xs="size === 'xs'"
                  :sm="size === 'sm'"
                  :lg="size === 'lg'"
                  :xl="size === 'xl'"
                  disabled
                  @click="logThis">
                  {{ size }} Button
                </CyButton>
              </td>
              <td>
                <CyButton
                  :theme="theme"
                  :variant="variant"
                  :xs="size === 'xs'"
                  :sm="size === 'sm'"
                  :lg="size === 'lg'"
                  :xl="size === 'xl'"
                  :loading="isLoading"
                  @click="logThis">
                  {{ size }} Button
                </CyButton>
              </td>
            </tr>
          </table>
        </v-col>
      </template>

      <template v-if="buttonType === 'textAndIcon'">
        <v-col
          v-for="variant of ['primary', 'secondary', 'tertiary']"
          :key="`icon-and-text:variant-${variant}`">
          <span class="test-area__header">{{ variant }}</span>
          <div>
            <CyButton
              v-for="(text, index) of ['some', 'grouped', 'buttons']"
              :key="`grouped:${index}__variant:${variant}`"
              :variant="variant"
              :icon="buttonIcon"
              @click="logThis">
              {{ text }}
            </CyButton>
          </div>
          <table
            v-for="theme of ['primary', 'secondary', 'error', 'warning', 'success', 'accent', 'grey']"
            :key="`theme-${theme}`"
            aria-describedby="themes">
            <thead>
              <th>normal</th>
              <th>disabled</th>
              <th>loading</th>
            </thead>
            <tr
              v-for="size of ['xs', 'sm', undefined, 'lg', 'xl']"
              :key="size || 'normal'">
              <td>
                <CyButton
                  :key="`variant:${variant}__theme:${theme}__size:${size || 'normal'}`"
                  :theme="theme"
                  :variant="variant"
                  :xs="size === 'xs'"
                  :sm="size === 'sm'"
                  :lg="size === 'lg'"
                  :xl="size === 'xl'"
                  :icon="buttonIcon"
                  @click="logThis">
                  {{ size }} Button
                </CyButton>
              </td>
              <td>
                <CyButton
                  :theme="theme"
                  :variant="variant"
                  :xs="size === 'xs'"
                  :sm="size === 'sm'"
                  :lg="size === 'lg'"
                  :xl="size === 'xl'"
                  disabled
                  :icon="buttonIcon"
                  @click="logThis">
                  {{ size }} Button
                </CyButton>
              </td>
              <td>
                <CyButton
                  :theme="theme"
                  :variant="variant"
                  :xs="size === 'xs'"
                  :sm="size === 'sm'"
                  :lg="size === 'lg'"
                  :xl="size === 'xl'"
                  :icon="buttonIcon"
                  :loading="isLoading"
                  @click="logThis">
                  {{ size }} Button
                </CyButton>
              </td>
            </tr>
          </table>
        </v-col>
      </template>

      <template v-if="buttonType === 'icon'">
        <v-col
          v-for="variant of ['primary', 'secondary', 'tertiary']"
          :key="`icon-only:variant-${variant}`">
          <span class="test-area__header">{{ variant }}</span>
          <div>
            <CyButton
              v-for="(icon, index) of ['close', 'check', 'add', 'edit', 'delete']"
              :key="`grouped:${index}__variant:${variant}`"
              :variant="variant"
              :icon="icon"
              icon-only
              @click="logThis"/>
          </div>
          <table
            v-for="theme of ['primary', 'secondary', 'error', 'warning', 'success', 'accent', 'grey']"
            :key="`theme-${theme}`"
            aria-describedby="themes"
            class="table--icons-only">
            <thead>
              <th/>
              <th>normal</th>
              <th>disabled</th>
              <th>loading</th>
            </thead>
            <tr
              v-for="size of ['xs', 'sm', undefined, 'lg', 'xl', 'xxl']"
              :key="size || 'normal'">
              <td>
                <span class="test-area__header--size">{{ size }}</span>
              </td>
              <td>
                <CyButton
                  :key="`variant:${variant}__theme:${theme}__size:${size || 'normal'}`"
                  :theme="theme"
                  :variant="variant"
                  :xs="size === 'xs'"
                  :sm="size === 'sm'"
                  :lg="size === 'lg'"
                  :xl="size === 'xl'"
                  :xxl="size === 'xxl'"
                  :icon="buttonIcon"
                  icon-only
                  @click="logThis"/>
              </td>
              <td>
                <CyButton
                  :theme="theme"
                  :variant="variant"
                  :xs="size === 'xs'"
                  :sm="size === 'sm'"
                  :lg="size === 'lg'"
                  :xl="size === 'xl'"
                  :xxl="size === 'xxl'"
                  disabled
                  :icon="buttonIcon"
                  icon-only
                  @click="logThis"/>
              </td>
              <td>
                <CyButton
                  :theme="theme"
                  :variant="variant"
                  :xs="size === 'xs'"
                  :sm="size === 'sm'"
                  :lg="size === 'lg'"
                  :xl="size === 'xl'"
                  :xxl="size === 'xxl'"
                  :icon="buttonIcon"
                  icon-only
                  :loading="isLoading"
                  @click="logThis"/>
              </td>
            </tr>
          </table>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import { getMissingOwnerObject } from '@/utils/helpers'

export default {
  name: 'CyButtonSandbox',
  data: () => ({
    isLoading: false,
    buttonType: 'text',
    buttonIcon: 'close',
    buttonIconOptions: [
      { header: 'font awesome icons' },
      'add',
      'check',
      'close',
      'delete',
      'edit',
      { header: 'material icons' },
      'help_outline',
      'info_outlined',
    ],
  }),
  computed: {
    missingOwner () {
      return {
        canonical: 'cli-api-key-math',
        description: 'Testing @:APIKey to replace login',
        id: 1,
        last_seven: 'oXG-oDE',
        last_used: 1616676223,
        name: 'Some awesome thing',
        rules: [{ action: 'organization:**', effect: 'allow', id: 1245, resources: [] }],
        owner: getMissingOwnerObject(),
      }
    },
    members () {
      return [
        {
          created_at: 1612527645,
          email: 'emily.carey@cycloid.io',
          family_name: 'Carey',
          given_name: 'Emily',
          id: 29,
          last_login_at: 1624280698,
          picture_url: 'https://12ax7web.s3.amazonaws.com/accounts/1/products/imported/i-give-zero-fox-t-shirt-teeturtle-500x500.jpg',
          updated_at: 1612527645,
          username: 'cycloid_emily',
        },
        {
          created_at: 0,
          email: 'nikola.savanovic@cycloid.io',
          family_name: 'Savanovic',
          given_name: 'Nikola',
          id: 287,
          last_login_at: 1624000462,
          picture_url: null,
          updated_at: 0,
          username: 'nikola_savanovic',
        },
        {
          created_at: 1619517988,
          email: 'daniele.gazzelloni@cycloid.io',
          family_name: 'Gazzelloni',
          given_name: 'Daniele',
          id: 135,
          last_login_at: 1623934386,
          picture_url: 'https://en.gravatar.com/userimage/3227962/5894493aa67b1dd5a98c135ce381fc9c.jpg',
          updated_at: 1619517988,
          username: 'daniele_gazzelloni_dev',
        },
      ]
    },
  },
  methods: {
    logThis () {
      console.info('btn clicked')
    },
  },
}
</script>

<style lang="scss" scoped>
.test-area {
  &__toolbar {
    width: fit-content;

    ::v-deep .v-toolbar__content {
      padding: 4px;
    }
  }

  &__header {
    color: cy-get-color("accent");
    font-size: 20px;

    &--size {
      color: cy-get-color("secondary");
      font-size: 16px;
      font-weight: $font-weight-bold;
    }
  }

  table {
    align-self: flex-start;
    height: 280px;
    margin-top: 2em;

    th {
      padding-left: 0.5em;
      text-align: left;
    }

    td {
      padding-right: 8px;
    }
  }

  .table--icons-only {
    height: 390px;
  }
}
</style>
