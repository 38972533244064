<template>
  <div>
    <h2 class="mb-5">
      Currency switcher
    </h2>

    <CyCloudCostManagementSidebarCurrencySwitcher/>
  </div>
</template>

<script>
import CyCloudCostManagementSidebarCurrencySwitcher from '@/components/cloud-cost-management/sidebar/currency-switcher.vue'

export default {
  name: 'CyCloudCostManagementSidebarCurrencySwitcherSandbox',
  components: {
    CyCloudCostManagementSidebarCurrencySwitcher,
  },
}
</script>
