<template>
  <div class="dump">
    <h4><code>code</code>: Object</h4>
    <CyDevDump :code="object"/>

    <h4 class="mt-6">
      <code>code</code>: Array
    </h4>
    <CyDevDump :code="array"/>

    <h4 class="mt-6">
      <code>min-height</code>
    </h4>
    <CyDevDump
      :code="1"
      :min-height="50"/>

    <h4 class="mt-6">
      <code>label</code>
    </h4>
    <CyDevDump
      label="My custom label"
      :code="'some crazy string'"/>
  </div>
</template>

<script>
import ProjectMock from 'mocks/Project'
import StackMock from 'mocks/Stack'

export default {
  name: 'CyDevDumpSandbox',
  data: () => ({
    object: new ProjectMock('lala'),
    array: [new StackMock(), new StackMock()],
  }),
}
</script>
