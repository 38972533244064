import { faker } from '@faker-js/faker'

export default class EmailMock {
  constructor ({
    email = faker.internet.email(),
    purpose = 'primary',
    verified = true,
  } = {}) {
    Object.assign(this, {
      email,
      purpose,
      verified,
    })
  }
}
