<template>
  <div>
    <h2 class="mb-5">
      Resource utilisation card
    </h2>

    <div>
      <CyQuotasResourceUtilisationCard
        v-for="resource in $static.resources"
        :key="`utilisation-card-${resource.type}`"
        :resource="resource"
        class="mb-8"/>
    </div>
  </div>
</template>

<script>
import CyQuotasResourceUtilisationCard from '@/components/quotas/resource-utilisation-card.vue'

export default {
  name: 'CyQuotasResourceUtilisationCardSandbox',
  components: {
    CyQuotasResourceUtilisationCard,
  },
  computed: {
    $static: () => ({
      resources: [
        {
          type: 'memory',
          used: 10000,
          allocated: 30000,
        },
        {
          type: 'storage',
          used: 2100000,
          allocated: 2500000,
        },
        {
          type: 'cpu',
          used: 90,
          allocated: 100,
        },
      ],
    }),
  },
}
</script>
