<template>
  <div class="feature-flags">
    <pre class="mb-4">{{ $showFeature }}</pre>
    <template v-for="(value, key) in $showFeature">
      <h2
        v-if="value"
        :key="key">
        I can show the {{ key }} feature!
      </h2>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CyFeatureFlagsSandbox',
}
</script>
