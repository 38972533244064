import Appearance from '@/utils/classes/Appearance'
import { faker } from '@faker-js/faker'

const { datatype } = faker

export default class AppearanceMock extends Appearance {
  constructor ({
    canonical = 'custom',
    color = {
      r: datatype.number({ min: 0, max: 255 }),
      g: datatype.number({ min: 0, max: 255 }),
      b: datatype.number({ min: 0, max: 255 }),
    },
    created_at = Date.now(),
    display_name = 'Cycloid',
    favicon = faker.image.imageUrl(16, 16),
    footer = null,
    is_active = false,
    logo = faker.image.imageUrl(120, 120),
    name = _.upperFirst(canonical),
    orgCanonical = null,
    tab_title = display_name,
    updated_at = Date.now(),
  } = {}) {
    super(orgCanonical, {
      canonical,
      color,
      created_at,
      display_name,
      favicon,
      footer,
      is_active,
      logo,
      name,
      tab_title,
      updated_at,
    })
  }
}
