<template>
  <div>
    <h2 class="mb-5">
      Trend chip
    </h2>

    <div
      v-for="{ label, value, dark } of $static.examples"
      :key="label"
      class="mb-5">
      <h4>{{ label }}</h4>
      <CyCloudCostManagementTrendChip
        :value="value"
        :dark="dark"/>
    </div>
  </div>
</template>

<script>
import CyCloudCostManagementTrendChip from '@/components/cloud-cost-management/trend-chip.vue'

export default {
  name: 'CyCloudCostManagementTrendChipSandbox',
  components: {
    CyCloudCostManagementTrendChip,
  },
  computed: {
    $static: () => ({
      examples: [
        {
          label: 'Percentage value, increase',
          value: 14,
        },
        {
          label: 'Percentage value, constant',
          value: 0,
        },
        {
          label: 'Percentage value, decrease',
          value: -7,
        },
        {
          label: 'Percentage value, increase, dark',
          value: 14,
          dark: true,
        },
        {
          label: 'Percentage value, constant, dark',
          value: 0,
          dark: true,
        },
        {
          label: 'Percentage value, decrease, dark',
          value: -7,
          dark: true,
        },
        {
          label: 'From/to value, increase',
          value: [100, 120],
        },
        {
          label: 'From/to value, constant',
          value: [100, 100],
        },
        {
          label: 'From/to value, decrease',
          value: [100, 75],
        },
        {
          label: 'From 0 to X',
          value: [0, 5],
        },
        {
          label: 'From 0 to 0',
          value: [0, 0],
        },
        {
          label: 'From 0 to -X',
          value: [0, -8],
        },
      ],
    }),
  },
}
</script>
