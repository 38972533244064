<template>
  <div class="wrapper">
    <h2 class="mb-5">
      Accounts table
    </h2>

    <CyCloudCostManagementAccountsTable/>
  </div>
</template>

<script>
import CyCloudCostManagementAccountsTable from '@/components/cloud-cost-management/accounts-table.vue'

export default {
  name: 'CyCloudCostManagementAccountsTableSandbox',
  components: {
    CyCloudCostManagementAccountsTable,
  },
}
</script>
