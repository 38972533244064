<template>
  <div>
    <vue-showdown :markdown="source"/>
  </div>
</template>

<script>
import { termsAndConditions } from '@/utils/markdown'

export default {
  name: 'CyMarkdownSandbox',
  computed: {
    source () {
      return termsAndConditions[this.$i18n.locale]
    },
  },
}
</script>
