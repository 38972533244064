<template>
  <div>
    <h2>Beta tag</h2>

    <CyBetaTag/>
  </div>
</template>

<script>
import CyBetaTag from '@/components/beta-tag.vue'

export default {
  name: 'CyBetaTagSandbox',
  components: {
    CyBetaTag,
  },
}
</script>
