<template>
  <v-row justify="space-between">
    <v-col cols="auto">
      <CyKeyNumbersCard
        :organization-name="orgName"
        :organization-canonical="orgCanonical"
        :stats="_.omit(stats, 'stacks')"
        :loading="loadingStats"/>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import CyKeyNumbersCard from '@/components/key-numbers-card.vue'

export default {
  name: 'CyKeyNumbersCardSandbox',
  components: {
    CyKeyNumbersCard,
  },
  computed: {
    ...mapState('organization', {
      loadingStats: (state) => state.fetchInProgress.summary,
      stats: (state) => state.summary,
    }),
    ...mapGetters([
      'isOrgSelected',
    ]),
  },
  watch: {
    orgCanonical: {
      async handler () {
        if (this.loading || !this.isOrgSelected) return
        await this.GET_ORG_SUMMARY()
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('organization', [
      'GET_ORG_SUMMARY',
    ]),
  },
}
</script>
