<template>
  <div>
    <h2>Condensed avatar</h2>

    <v-row>
      <v-col cols="4">
        <div
          v-for="({ label, props }, index) of $static.examples"
          :key="`avatars-${index}`"
          class="mb-5">
          <h4>{{ label }}</h4>
          <CyCondensedAvatar v-bind="props"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { aws, azurerm, google, flexibleengine } from '@/assets/icons'
import CyCondensedAvatar from '@/components/condensed-avatar.vue'

export default {
  name: 'CyCondensedAvatarSandbox',
  components: {
    CyCondensedAvatar,
  },
  computed: {
    $static: () => ({
      examples: [
        {
          label: 'Single item',
          props: {
            images: [
              {
                src: aws.logo,
                title: 'AWS',
              },
            ],
          },
        },
        {
          label: 'Multiple items',
          props: {
            images: [
              {
                src: aws.logo,
                title: 'AWS',
              },
              {
                src: google.logo,
                title: 'GCP',
              },
              {
                src: azurerm.logo,
                title: 'Azure',
              },
            ],
          },
        },
        {
          label: 'Multiple items (> 3)',
          props: {
            images: [
              {
                src: aws.logo,
                title: 'AWS',
              },
              {
                src: google.logo,
                title: 'GCP',
              },
              {
                src: azurerm.logo,
                title: 'Azure',
              },
              {
                src: flexibleengine.logo,
                title: 'Flexible engine',
              },
            ],
          },
        },
        {
          label: 'Greyscale',
          props: {
            images: [
              {
                src: aws.logo,
                title: 'AWS',
              },
              {
                src: google.logo,
                title: 'GCP',
              },
              {
                src: azurerm.logo,
                title: 'Azure',
              },
              {
                src: flexibleengine.logo,
                title: 'Flexible engine',
              },
            ],
            greyscale: true,
          },
        },
      ],
    }),
  },
}
</script>
