<template>
  <div class="wrapper">
    <h2 class="mb-5">
      Provider breakdown widget
    </h2>

    <CyCloudCostManagementWidgetsProviderBreakdown/>
  </div>
</template>

<script>
import CyCloudCostManagementWidgetsProviderBreakdown from '@/components/cloud-cost-management/widgets/provider-breakdown.vue'

export default {
  name: 'CyCloudCostManagementWidgetsProviderBreakdownSandbox',
  components: {
    CyCloudCostManagementWidgetsProviderBreakdown,
  },
}
</script>
