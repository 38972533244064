<template>
  <div>
    <h2 class="mb-5">
      Group-by filter
    </h2>

    <CyCloudCostManagementSidebarGroupByFilter/>
  </div>
</template>

<script>
import CyCloudCostManagementSidebarGroupByFilter from '@/components/cloud-cost-management/sidebar/group-by-filter.vue'

export default {
  name: 'CyCloudCostManagementSidebarGroupByFilterSandbox',
  components: {
    CyCloudCostManagementSidebarGroupByFilter,
  },
}
</script>
