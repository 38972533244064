/* eslint-disable camelcase */
import { faker } from '@faker-js/faker'
import AppearanceMock from './Appearance'

const { random, datatype } = faker

export default class OrganizationMock {
  constructor ({
    blocked = [],
    can_children_create_appearance = false,
    can_create_appearance = false,
    ci_team_name = '12345-67890-99999',
    created_at = Date.now(),
    has_children = false,
    id = datatype.number(),
    name = random.word(),
    quotas = false,
    subscription = {},
    canonical = _.kebabCase(name),
    appearance = new AppearanceMock(canonical),
  } = {}) {
    Object.assign(this, {
      id,
      name,
      appearance,
      blocked,
      can_children_create_appearance,
      can_create_appearance,
      canonical,
      ci_team_name,
      created_at,
      has_children,
      quotas,
      subscription,
    })
  }
}
