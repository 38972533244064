/* eslint-disable camelcase */
import { displayName } from '@/utils/helpers'
import { faker } from '@faker-js/faker'

const { datatype, internet, name } = faker

export default class MemberMock {
  constructor ({
    created_at = 1503225026,
    email = internet.email(),
    family_name = name.lastName(),
    given_name = name.firstName(),
    id = datatype.number(),
    last_login_at = 1614282288,
    picture_url = faker.image.imageUrl(120, 120),
    invitation_state = 'accepted',
    role = {
      id: datatype.number(),
      name: 'developer',
      canonical: 'developer',
      description: 'A developer of the team',
      policies: [
        {
          id: 21,
          code: 'team:read',
          entities: [],
          description: 'Grant the access to read the teams of an organization',
        },
        {
          id: 42,
          code: 'member:read',
          entities: [],
          description: 'Grant the access to read the members',
        },
        {
          id: 60,
          code: 'project:read',
          entities: [],
          description: 'Grant the access to projects',
        },
      ],
    },
    updated_at = 1503225026,
    username = internet.userName(),
  } = {}) {
    Object.assign(this, {
      created_at,
      email,
      family_name,
      given_name,
      id,
      last_login_at,
      invitation_state,
      picture_url,
      role,
      updated_at,
      username,
    })
  }

  get displayName () {
    return displayName(this)
  }
}
