<template>
  <div>
    <div>
      <label>Number of widgets</label>
      <CyFormsWidgetSliderRange
        v-model="count"
        dense
        :max="max"
        :min="min"
        class="mt-4"/>
    </div>
    <v-row
      justify="space-between"
      class="mt-8">
      <v-col>
        <transition-group
          name="slide-fade-top"
          tag="div"
          class="grid grid--favorite-metrics mt-12">
          <CyKpiWidgetsDashboardWidget
            v-for="KPI in _.take(KPIs, count)"
            :key="KPI.canonical"
            :kpi="KPI"/>
        </transition-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CyFormsWidgetSliderRange from '@/components/forms-widget/slider-range.vue'
import CyKpiWidgetsDashboardWidget from '@/components/kpi-widgets/dashboard-widget.vue'

export default {
  name: 'CyKpiWidgetsDashboardWidgetSandbox',
  components: {
    CyKpiWidgetsDashboardWidget,
    CyFormsWidgetSliderRange,
  },
  data: () => ({
    count: 4,
    min: 2,
    max: 14,
  }),
  computed: {
    ...mapState('organization/project/kpi', {
      KPIs: (state) => state.KPIs,
    }),
  },
  created () {
    this.GET_KPIS()
  },
  methods: {
    ...mapActions('organization/project/kpi', [
      'GET_KPIS',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-gap: 24px;

  &--favorite-metrics {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
}
</style>
