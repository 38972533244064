<template>
  <div>
    <h2 class="mb-5">
      Filter tree
    </h2>

    <div>
      <CyFilterTree
        v-model="value"
        :filter-tree="$static.filterTree"/>
    </div>
    Values: {{ value }}
  </div>
</template>

<script>
import CyFilterTree from '@/components/filter-tree.vue'

export default {
  name: 'CyFilterTreeSandbox',
  components: {
    CyFilterTree,
  },
  data: () => ({
    value: ['projects.beta', 'tags.cost-center.acme', 'tags.cost-center.theta'],
  }),
  computed: {
    $static () {
      return {
        filterTree: {
          providers: {
            aws: 3,
            gcp: 1,
            azure: 2,
          },
          projects: {
            beta: 1,
            demo: 2,
            'foo-app': 2,
          },
          tags: {
            cluster: {
              'cluster-1': 2,
            },
            'cost-center': {
              acme: 5,
              beta: 1,
              theta: 1,
            },
          },
        },
      }
    },
  },
}
</script>
