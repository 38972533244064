import { faker } from '@faker-js/faker'

const { datatype, lorem } = faker

/** Create new API mock response object
 * - all constructor params are optional
 * - if not passing any params, you must still pass an empty object
 * - all mocked property values will default to `faker`s `lorem.word()` if not provided
 * @optionsObject
 * @param {Object.isError} [.isError=false]             - if true, returns an error response
 * @param {Object.isList} [.isList=false]               - if true, returns a list response; Array
 * @param {Object.properties} [.properties={}]          - populates specified properties on data response
 * @param {Object.hasCanonical} [.hasCanonical=true]    - if true, populates canonical property on data response
 * @param {Object.canonical} [.canonical=lorem.word()]  - populates canonical property on data response
 * @returns           Object
 * @example
 *                    new APIResponse()
 *                    // default return
 *                    { data: { canonical: lorem.word() } }
 * @example
 *                    new APIResponse({ properties: { meh } })
 *                    // returns
 *                    { data: { canonical: lorem.word(), meh: lorem.word() } }
 * @example
 *                    new APIResponse({ isError: true })
 *                    // returns
 *                    { errors: [{ code: lorem.word(), message: lorem.paragraph() }] }
 */
export default class APIResponse {
  constructor ({
    isError = false,
    error = {},
    isList = false,
    properties = {},
    hasCanonical = true,
    canonical = lorem.word(),
  } = {}) {
    if (isError) {
      /* 1️⃣ ERRORS
        e.g. {
          errors: [{ code: 'aqua', message: 'ipsum lorem qui quot' }]
        }
      */
      this.errors = [{
        code: lorem.word(),
        message: lorem.paragraph(),
        details: null,
        ...error,
      }]
      return
    }

    if (isList) {
      /* 2️⃣ LIST
        e.g. {
          data: [{ canonical: 'ipsum', name: 'lorem' }]
          pagination: { index: 1, size: 3, total: 3 }
        }
      */
      const size = datatype.number({ min: 1, max: 100 })
      const total = size + datatype.number({ min: 0, max: 1 })
      this.pagination = { index: 1, size, total }
      this.data = []
      if (!properties) return
      Object.keys(properties).forEach((property) => {
        this.data = [...this.data, { [property]: properties[property] ?? lorem.word() }]
      })
    } else {
      /* 3️⃣ ITEM
        e.g. {
          data: { canonical: 'qui', name: 'ipsum', schema: 'quo' }
        }
      */
      if (hasCanonical) this.data = { canonical }
      Object.keys(properties).forEach((property) => {
        this.data = { ...this.data, ...{ [property]: properties[property] ?? lorem.word() } }
      })
    }
  }
}
