<template>
  <div>
    <h2 class="mb-5">
      Date range filter
    </h2>

    <CyCloudCostManagementSidebarDateRangeFilter/>
  </div>
</template>

<script>
import CyCloudCostManagementSidebarDateRangeFilter from '@/components/cloud-cost-management/sidebar/date-range-filter.vue'

export default {
  name: 'CyCloudCostManagementSidebarDateRangeFilterSandbox',
  components: {
    CyCloudCostManagementSidebarDateRangeFilter,
  },
}
</script>
