<template>
  <div>
    <CyDevDump
      label="Member data"
      :code="member"/>

    <div class="ma-4 mb-8">
      <v-text-field
        v-for="field of [
          'email',
          'family_name',
          'given_name',
          'username',
        ]"
        :key="field"
        v-model="member[field]"
        :label="field"/>
    </div>

    <CyAlert
      title="Note:"
      content="<code>username</code> hides at smaller screen sizes"/>
    <v-row>
      <v-col
        v-for="{ label, props } of sizes"
        :key="label"
        :cols="4">
        <h2 v-html="label"/>
        <section class="d-flex flex-column">
          <strong class="subtitle">
            <i>Default</i>
          </strong>
          <CyMember
            :member="member"
            v-bind="props"/>
        </section>

        <section class="mt-8 d-flex flex-column">
          <strong class="subtitle">
            Simple
          </strong>
          <CyMember
            :member="member"
            simple
            v-bind="props"/>
        </section>

        <section class="mt-8 d-flex flex-column">
          <strong class="subtitle">
            No image
          </strong>
          <CyMember
            :member="{ ...member, picture_url: null }"
            v-bind="props"/>
        </section>

        <section class="mt-8 d-flex flex-column">
          <strong class="subtitle">
            Avatar only
          </strong>
          <CyMember
            :member="member"
            avatar-only
            v-bind="props"/>
        </section>

        <section class="mt-8 d-flex flex-column">
          <strong class="subtitle">
            Missing
          </strong>
          <CyMember
            :member="missingMember"
            v-bind="props"/>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getMissingOwnerObject } from '@/utils/helpers'
import MemberMock from 'mocks/Member'

export default {
  name: 'CyMemberSandbox',
  data: () => ({
    member: new MemberMock(),
    missingMember: getMissingOwnerObject(),
    sizes: [
      {
        label: '<i>Default</i>',
        props: {},
      },
      {
        label: 'Small',
        props: { sm: true },
      },
      {
        label: 'Large',
        props: { lg: true },
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.subtitle {
  width: 100px;
  color: cy-get-color("primary", "light-3");
}
</style>
