import { faker } from '@faker-js/faker'
import EnvironmentMock from './Environment'
import MemberMock from './Member'
import StackMock from './Stack'

export default class ProjectMock {
  constructor (name, {
    canonical = _.kebabCase(name),
    configRepositoryId,
    configRepositoryCanonical,
    id = faker.datatype.number(),
    infraImportStatus,
    environments = ['dev', 'staging', 'prod']
      .map((envCanonical) => new EnvironmentMock({ canonical: envCanonical })),
    owner = new MemberMock(),
    description = 'this is a project description',
    serviceCatalogRef = 'seraf:stack-dummy',
    serviceCatalog = new StackMock({ ref: serviceCatalogRef }),
    teamCanonical = null,
    createdAt = 1603807906,
    updatedAt = 1610702464,
    favorite,
  } = {}) {
    Object.assign(this, {
      canonical,
      description,
      environments,
      id,
      name,
      owner,
      created_at: createdAt,
      updated_at: updatedAt,
      service_catalog: _.cloneDeep(serviceCatalog),
    })
    if (_.isBoolean(favorite)) this.favorite = favorite
    if (teamCanonical) this.team_canonical = teamCanonical
    if (configRepositoryId) this.config_repository_id = configRepositoryId
    if (configRepositoryCanonical) this.config_repository_canonical = configRepositoryCanonical
    if (infraImportStatus) this.service_catalog.import_status = infraImportStatus
  }
}
