<template>
  <div>
    <h2 class="mb-5">
      Granularity selector
    </h2>

    <CyCloudCostManagementSidebarGranularitySelector/>
  </div>
</template>

<script>
import CyCloudCostManagementSidebarGranularitySelector from '@/components/cloud-cost-management/sidebar/granularity-selector.vue'

export default {
  name: 'CyCloudCostManagementSidebarGranularitySelectorSandbox',
  components: {
    CyCloudCostManagementSidebarGranularitySelector,
  },
}
</script>
