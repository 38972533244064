<template>
  <div>
    <h2 class="mb-5">
      Checkbox filter
    </h2>

    <div
      v-for="{ label, id, title, options } of $static.examples"
      :key="label"
      class="mb-5">
      <h4>{{ label }}</h4>
      <v-expansion-panels>
        <CyCloudCostManagementSidebarCheckboxFilter
          :filter-id="id"
          :title="title"
          :options="options"/>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import CyCloudCostManagementSidebarCheckboxFilter from '@/components/cloud-cost-management/sidebar/checkbox-filter.vue'

export default {
  name: 'CyCloudCostManagementSidebarCheckboxFilterSandbox',
  components: {
    CyCloudCostManagementSidebarCheckboxFilter,
  },
  computed: {
    $static: () => ({
      examples: [
        {
          label: 'Empty checkbox list',
          title: 'Resource',
          id: 'resource',
          options: [],
        },
        {
          label: 'List with long display names',
          title: 'A very long and exagerated filter name that is really too long to be practical',
          id: 'tags',
          options: [
            {
              value: 'a-very-long-and-exagerated-category-name-that-is-really-too-long-to-be-practical:a-very-long-and-exagerated-option-name-that-is-really-too-long-to-be-practical',
              count: 4,
            },
          ],
        },
        {
          label: 'Simple checkbox list',
          title: 'Region',
          id: 'region',
          options: [
            {
              value: 'eu-west-1',
              count: 7,
            },
            {
              value: 'eu-west-2',
              count: 3,
            },
            {
              value: 'eu-south-1',
              count: 1,
            },
            {
              value: 'us-west-1',
              count: 15,
            },
            {
              value: 'us-west-2',
              count: 4,
            },
            {
              value: 'ap-northeast-1',
              count: 5,
            },
            {
              value: 'ap-northeast-2',
              count: 3,
            },
            {
              value: 'ap-northeast-3',
              count: 2,
            },
          ],
        },
        {
          label: 'Nested checkbox list (tags)',
          title: 'Tags',
          id: 'tags',
          options: [
            {
              value: 'app:frontend-app',
              count: 17,
            },
            {
              value: 'app:backend-app',
              count: 12,
            },
            {
              value: 'app:react-app',
              count: 14,
            },
            {
              value: 'owner:john-doe',
              count: 7,
            },
            {
              value: 'owner:jane-doe',
              count: 12,
            },
          ],
        },
      ],
    }),
  },
  created () {
    this.SET_QUERY_FILTER({ key: 'region', value: ['eu-west-1', 'eu-south-1'] })
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_QUERY_FILTER',
    ]),
  },
}
</script>

<style lang="scss" scoped>
  .v-expansion-panels {
    max-width: 300px;
  }
</style>
