var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 text-left pa-4 elevation-1"},[_c('h2',{staticClass:"mb-3"},[_vm._v(" Tags ")]),_c('div',[_c('h3',[_vm._v("Variants")]),_c('CyTagList',{attrs:{"tags":[
        { content: 'default', variant: 'default' },
        { content: 'primary', variant: 'primary' },
        { content: 'secondary', variant: 'secondary' },
        { content: 'accent', variant: 'accent' },
        { content: 'success', variant: 'success' },
        { content: 'warning', variant: 'warning' },
        { content: 'error', variant: 'error' } ]},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
      var tag = ref.tag;
return [_c('CyTag',{attrs:{"variant":tag.variant,"element-type":"button"}},[_vm._v(" "+_vm._s(tag.content)+" ")])]}}])})],1),_c('div',{staticClass:"mt-3"},[_c('h3',[_vm._v("Sizes")]),_c('div',[_c('CyTag',[_vm._v(" normal ")])],1),_c('div',{staticClass:"mt-2"},[_c('CyTag',{attrs:{"small":""}},[_vm._v(" small ")])],1)]),_c('div',{staticClass:"mt-3"},[_c('h3',[_vm._v("Type")]),_c('p',[_vm._v(" HTML tag can be "),_c('CyTag',{attrs:{"element-type":"button"}},[_vm._v(" button ")]),_vm._v(", "),_c('CyTag',{attrs:{"href":"trololo","target":"_blank"}},[_vm._v(" link ")]),_vm._v(" or default to "),_c('CyTag',[_vm._v(" span ")]),_vm._v(". ")],1)]),_c('div',{staticClass:"mt-3"},[_c('h3',[_vm._v("Labelled tag")]),_c('CyTagList',{attrs:{"tags":[
        { label: 'UUID', content: '881da1d4-6227-11ea-bc55-0242ac130003' },
        { label: 'type', content: 'ephemeral' },
        { label: 'alertname', content: 'CPUUsage' } ]}})],1),_c('div',{staticClass:"mt-3"},[_c('h3',[_vm._v("With icon")]),_c('div',[_c('CyTag',{attrs:{"icon-before":"fingerprint","element-type":"button"}},[_vm._v(" fingerprint ")]),_c('CyTag',{staticClass:"ml-1",attrs:{"variant":"success","icon-before":"eco","element-type":"button"}},[_vm._v(" eco ")]),_c('CyTag',{staticClass:"ml-1",attrs:{"variant":"error","icon-after":"bug_report","element-type":"button"}},[_vm._v(" bug_report ")]),_c('CyTag',{staticClass:"ml-1",attrs:{"variant":"accent","icon-after":"sports_esports","element-type":"button"}},[_vm._v(" sports_esports ")])],1),_c('div',{staticClass:"mt-1"},[_c('CyTag',{attrs:{"small":"","icon-before":"fingerprint","element-type":"button"}},[_vm._v(" fingerprint ")]),_c('CyTag',{staticClass:"ml-1",attrs:{"small":"","variant":"success","icon-before":"eco","element-type":"button"}},[_vm._v(" eco ")]),_c('CyTag',{staticClass:"ml-1",attrs:{"small":"","variant":"error","icon-after":"bug_report","element-type":"button"}},[_vm._v(" bug_report ")]),_c('CyTag',{staticClass:"ml-1",attrs:{"small":"","variant":"accent","icon-after":"sports_esports","element-type":"button"}},[_vm._v(" sports_esports ")])],1)]),_c('div',{staticClass:"light-tag-block mt-6"},[_c('div',[_c('h3',{staticClass:"white--text"},[_vm._v(" Regular list ")]),_c('div',{staticClass:"light-tag-block__constrained"},[_c('CyTagList',{attrs:{"tags":[
            { content: 'cycloid' },
            { content: 'devops' },
            { content: 'cloud' },
            { content: 'aws' },
            { content: 'kubernetes' },
            { content: 'cluster' },
            { content: 'pipeline' },
            { content: 'infrastructure' },
            { content: 'deployment' },
            { content: 'instance' } ]}})],1)]),_c('div',{staticClass:"mt-3"},[_c('h3',{staticClass:"white--text"},[_vm._v(" Small list ")]),_c('CyTagList',{attrs:{"small":"","tags":[
          { content: 'alpha' },
          { content: 'beta' },
          { content: 'gamma' } ]}})],1),_c('div',{staticClass:"mt-3"},[_c('h3',{staticClass:"white--text"},[_vm._v(" Contained list ")]),_c('div',{staticClass:"pa-3",staticStyle:{"width":"400px","max-width":"100%","overflow":"auto","background-color":"white","resize":"horizontal"}},[_c('CyTagList',{attrs:{"contained":"","tags":[
            { content: 'cycloid' },
            { content: 'devops' },
            { content: 'cloud' },
            { content: 'aws' },
            { content: 'kubernetes' },
            { content: 'cluster' },
            { content: 'pipeline' },
            { content: 'infrastructure' },
            { content: 'deployment' },
            { content: 'instance' } ]}}),_c('CyTagList',{staticClass:"mt-3",attrs:{"contained":"","small":"","tags":[
            { content: 'cycloid' },
            { content: 'devops' },
            { content: 'cloud' },
            { content: 'aws' },
            { content: 'kubernetes' },
            { content: 'cluster' },
            { content: 'pipeline' },
            { content: 'infrastructure' },
            { content: 'deployment' },
            { content: 'instance' } ]}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }