/* eslint-disable camelcase */
import EmailMock from './email'

/**
 * Should match Schema used here: https://docs.staging.cycloid.io/api/index.html#operation/getUserAccount
 *
 * @param {Boolean} [isConverted=false]   When set to true, will adapt properties to how the data is stored in Vuex
 *                                          See SET_PROFILE, in the user module, for these exact changes.
 */
export class ProfileMock {
  constructor ({
    isConverted = false,
    created_at = 1530540012,
    updated_at = 1582736903,
    last_login = 1582736903,
    emails = [new EmailMock()],
    username = 'pocahontas_lover',
    given_name = 'John',
    family_name = 'Smith',
    invitation_state = 'accepted',
    locale = 'en',
    picture_url = 'https://12ax7web.s3.amazonaws.com/accounts/1/products/imported/i-give-zero-fox-t-shirt-teeturtle-500x500.jpg',
  } = {}) {
    Object.assign(this, {
      created_at,
      updated_at,
      last_login,
      emails,
      username,
      given_name,
      family_name,
      invitation_state,
      locale,
      picture_url,
      ...(isConverted
        ? { country_code: 'GB' }
        : {
            country: {
              code: 'GB',
              name: 'United Kingdom',
            },
          }
      ),
    })
  }
}

export default ProfileMock
