<template>
  <div :key="forceResetAt">
    <CyButton
      class="align-self-end"
      @click="resetAll">
      Reset all
    </CyButton>

    <h2 class="mt-10">
      Required | No owner
    </h2>
    <CySelectOwner required/>

    <h2 class="mt-10">
      Not required | No owner
    </h2>
    <CySelectOwner/>

    <h2 class="mt-10">
      Required | Has owner
    </h2>
    <CySelectOwner
      required
      v-bind="{ currentOwner }"/>

    <h2 class="mt-10">
      Not required | Has owner
    </h2>
    <CySelectOwner v-bind="{ currentOwner }"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CySelectOwner from '@/components/select-owner.vue'

export default {
  name: 'CySelectOwnerSandbox',
  components: {
    CySelectOwner,
  },
  data: () => ({
    currentOwner: null,
    forceResetAt: null,
  }),
  computed: {
    ...mapGetters(['orgMembers']),
  },
  async created () {
    await this.$evaluateUserActions('GetOrgMembers')
    await this.FETCH_AVAILABLE({ keyPath: 'members' })
    this.currentOwner = _.last(this.orgMembers)
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    resetAll () {
      this.forceResetAt = Date.now()
    },
  },
}
</script>

<style lang="scss" scoped>
.owner-select {
  flex: 0 1 auto;
}
</style>
