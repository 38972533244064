<template>
  <div class="wrapper">
    <h2 class="mb-5">
      Total cost widget
    </h2>

    <CyCloudCostManagementWidgetsTotalCost/>
  </div>
</template>

<script>
import CyCloudCostManagementWidgetsTotalCost from '@/components/cloud-cost-management/widgets/total-cost.vue'

export default {
  name: 'CyCloudCostManagementWidgetsTotalCostSandbox',
  components: {
    CyCloudCostManagementWidgetsTotalCost,
  },
}
</script>
