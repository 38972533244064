<template>
  <div>
    <h2 class="mb-5">
      Provider card
    </h2>

    <div class="providers-card-container d-flex justify-space-between">
      <CyCloudCostManagementProviderCard
        v-for="providerCost in $static.providerData"
        :key="`provider-card-${providerCost.canonical}`"
        :provider-data="providerCost"/>
    </div>
  </div>
</template>

<script>
import CyCloudCostManagementProviderCard from '@/components/cloud-cost-management/provider-card.vue'
import { daysAgo } from 'test-unit/helpers'

export default {
  name: 'CyCloudCostManagementProviderCardSandbox',
  components: {
    CyCloudCostManagementProviderCard,
  },
  computed: {
    $static: () => ({
      providerData: [
        {
          canonical: 'aws',
          cost: 1253.13,
          currency: 'EUR',
          costData: [
            { date: daysAgo(7), cost: 1103.00 },
            { date: daysAgo(6), cost: 5500.13 },
            { date: daysAgo(5), cost: 4900.13 },
            { date: daysAgo(4), cost: 3042.13 },
            { date: daysAgo(3), cost: 1153.13 },
            { date: daysAgo(2), cost: 1853.13 },
            { date: daysAgo(1), cost: 1253.13 },
            { date: Date.now(), cost: 1253.13 },
          ],
        },
        {
          canonical: 'google',
          cost: 13511.25,
          currency: 'EUR',
          costData: [
            { date: daysAgo(7), cost: 13511.25 },
            { date: daysAgo(6), cost: 22500.13 },
            { date: daysAgo(5), cost: 9400.13 },
            { date: daysAgo(4), cost: 33042.13 },
            { date: daysAgo(3), cost: 1853.77 },
            { date: daysAgo(2), cost: 2353.13 },
            { date: daysAgo(1), cost: 4033.44 },
            { date: Date.now(), cost: 13511.25 },
          ],
        },
        {
          canonical: 'azurerm',
          cost: 5600.08,
          currency: 'EUR',
          costData: [
            { date: daysAgo(7), cost: 6003.00 },
            { date: daysAgo(6), cost: 4500.13 },
            { date: daysAgo(5), cost: 2430.13 },
            { date: daysAgo(4), cost: 8042.13 },
            { date: daysAgo(3), cost: 8153.13 },
            { date: daysAgo(2), cost: 8853.13 },
            { date: daysAgo(1), cost: 9834.54 },
            { date: Date.now(), cost: 5600.08 },
          ],
        },
      ],
    }),
  },
}
</script>

<style lang="scss" scoped>
.providers-card-container {
  max-width: 980px;
}
</style>
