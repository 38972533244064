export default class StackMock {
  constructor (stack = {}) {
    Object.assign(this, {
      author: 'Cycloid',
      canonical: 'infra-amazon-s3',
      created_at: 1519918715,
      dependencies: ['aws'],
      description: 'This infrastructure will deploy a S3 bucket on AWS.',
      id: 4,
      image: 'https://raw.githubusercontent.com/cycloid-community-catalog/infra-amazon-s3/master/icon.png',
      keywords: ['s3', 'iam', 'terraform', 'provider:aws'],
      name: 'Amazon S3 Bucket',
      quota_enabled: false,
      ref: 'seraf:infra-amazon-s3',
      service_catalog_source_canonical: 'infra-amazon-s3',
      status: 'public',
      technologies: [
        {
          technology: 'Amazon s3',
          version: 'n/a',
        },
        {
          technology: 'terraform',
          version: 'n/a',
        },
      ],
      template: false,
      trusted: true,
      updated_at: 1602255776,
      ...stack,
    })
  }
}
