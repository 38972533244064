export default {
  /* eslint sort-keys: "error" */
  CyButton: {
    render (h) {
      return h('div', [
        this.$slots.default,
      ])
    },
  },
  CyMenu: {
    render (h) {
      return h('CyMenu-stub', [
        this.$slots.default,
        this.$scopedSlots.activator({ on: jest.fn() }),
      ])
    },
  },
  CyTooltip: {
    render (h) {
      return h('div', [
        this.$slots.default,
        this.$scopedSlots.activator({ on: jest.fn() }),
      ])
    },
  },
  'v-expansion-panel-header': {
    render (h) {
      return h('v-expansion-panel-header-stub', [
        this.$slots.actions,
        this.$scopedSlots.default({ open: jest.fn() }),
      ])
    },
  },
  'v-menu': {
    render (h) {
      return h('v-menu-stub', [
        this.$slots.default,
        this.$scopedSlots.activator({ on: jest.fn() }),
      ])
    },
  },
  'v-timeline-item': {
    render (h) {
      return h('v-timeline-item-stub', _.values(this.$slots))
    },
  },
}
