<template>
  <div>
    <div>
      <label>Number of cards</label>
      <CyFormsWidgetSliderRange
        v-model="count"
        dense
        :max="max"
        :min="min"
        class="mt-4"/>
    </div>
    <v-row
      justify="space-between"
      class="mt-8">
      <v-col>
        <div class="grid grid--favorite-projects">
          <CyProjectMiniCard
            v-for="(project) in _.take(projects, count)"
            :key="project.id"
            :project="project"
            :loading="loadingProjects"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CyFormsWidgetSliderRange from '@/components/forms-widget/slider-range.vue'
import CyProjectMiniCard from '@/components/project-mini-card.vue'

export default {
  name: 'CyProjectMiniCardSandbox',
  components: {
    CyProjectMiniCard,
    CyFormsWidgetSliderRange,
  },
  data: () => ({
    count: 4,
    min: 2,
    max: 14,
  }),
  computed: {
    ...mapState('organization', {
      loadingProjects: (state) => state.fetchInProgress.projects,
      projects: (state) => state.available.projects,
    }),
  },
  created () {
    this.getProjects()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    async getProjects () {
      await this.FETCH_AVAILABLE({ keyPath: 'projects' })
    },
  },
}
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-gap: 24px;

  &--favorite-projects {
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  }
}
</style>
