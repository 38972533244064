<template>
  <div>
    <h2 class="mb-5">
      Filter list
    </h2>

    <CyCloudCostManagementSidebarFilterList :filters="$static.filters"/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import CyCloudCostManagementSidebarFilterList from '@/components/cloud-cost-management/sidebar/filter-list.vue'

export default {
  name: 'CyCloudCostManagementSidebarFilterListSandbox',
  components: {
    CyCloudCostManagementSidebarFilterList,
  },
  computed: {
    $static: () => ({
      filters: [
        'project',
        'environment',
        'region',
        'resource',
        'resource_type',
        'component',
        'linked_account',
        'tags',
        'tagged_resources',
        'untagged_resources',
      ],
      mockValues: {
        project: [
          'test-project',
        ],
        region: [
          'eu-west-1',
          'eu-west-2',
          'eu-south-1',
          'us-west-1',
          'us-west-2',
          'ap-northeast-1',
          'ap-northeast-2',
          'ap-northeast-3',
        ],
        tags: {
          app: [
            'frontend-app',
            'backend-app',
            'react-app',
          ],
          owner: [
            'john-doe',
            'jane-doe',
          ],
        },
      },
    }),
  },
  created () {
    this.SET_FILTER_VALUES(this.$static.mockValues)
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_FILTER_VALUES',
    ]),
  },
}
</script>
